import {StyleSheet} from 'react-native';
import {
  backgroundColor,
  fontColor,
  fontSize,
  basicColor,
  borderRadiusSize,
  iconSize,
  imageSize,
} from './base.variable';
import globalStore from '@/services/global.state';

export const fill = StyleSheet.create({
  fillW: {
    width: '100%',
  },
  fillH: {
    height: '100%',
  },
  fill: {
    height: '100%',
    width: '100%',
  },
});

export const position = StyleSheet.create({
  rel: {
    position: 'relative',
  },
  abs: {
    position: 'absolute',
  },
});

export const overflow = StyleSheet.create({
  hidden: {
    overflow: 'hidden',
  },
  visible: {
    overflow: 'visible',
  },
});

export const flex = StyleSheet.create({
  flex: {
    display: 'flex',
  },
  col: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  start: {
    justifyContent: 'flex-start',
  },
  end: {
    justifyContent: 'flex-end',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  centerByCol: {
    alignItems: 'center',
  },
  centerByRow: {
    justifyContent: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  around: {
    justifyContent: 'space-around',
  },
  between: {
    justifyContent: 'space-between',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flex1: {
    flex: 1,
    ...overflow.hidden,
  },
  flex2: {
    flex: 2,
    ...overflow.hidden,
  },
  flex3: {
    flex: 3,
    ...overflow.hidden,
  },
  flex4: {
    flex: 4,
    ...overflow.hidden,
  },
  shrink0: {
    flexShrink: 0,
  },
  basis0: {
    flexBasis: 0,
  },
});

export const font = StyleSheet.create({
  primary: {
    color: basicColor.primary,
  },
  red: {
    color: basicColor.red,
  },
  white: {
    color: basicColor.white,
  },
  main: {
    color: fontColor.main,
  },
  second: {
    color: fontColor.second,
  },
  accent: {
    color: fontColor.accent,
  },
  secAccent: {
    color: fontColor.secAccent,
  },
  /** 极小号字体 10 */
  xs: {
    fontSize: fontSize.xs,
  },
  /** 小号字体 12 */
  fs: {
    fontSize: fontSize.s,
  },
  /** 中号字体 14 */
  fm: {
    fontSize: fontSize.m,
  },
  /** 大号字体 16 */
  fl: {
    fontSize: fontSize.l,
  },
  /** 特大号字体 20 */
  fxl: {
    fontSize: fontSize.xl,
  },
  bold: {
    fontWeight: 'bold',
  },
  fontDin: {
    fontFamily: 'din',
  },
  fontAnybody: {
    fontFamily: 'Anybody',
  },
  fontInter: {
    fontFamily: 'Inter',
  },
  fontInterBold: {
    fontFamily: 'Inter-Bold',
  },
  fontDinBold: {
    fontFamily: 'din-bold',
  },
  fontDinMid: {
    fontFamily: 'din-mid',
  },
  fontDinBlack: {
    fontFamily: 'din-black',
  },
  italic: {
    fontStyle: 'italic',
  },
  lineThrough: {
    textDecorationLine: 'line-through',
  },
  center: {
    textAlign: 'center',
  },
});

export const background = StyleSheet.create({
  primary: {
    backgroundColor: basicColor.primary,
  },
  red: {
    backgroundColor: basicColor.red,
  },
  white: {
    backgroundColor: basicColor.white,
  },
  transparent: {
    backgroundColor: basicColor.transparent,
  },
  /** 蓝 */
  main: {
    backgroundColor: backgroundColor.main,
  },
  /** 橙 */
  second: {
    backgroundColor: backgroundColor.second,
  },
  /** 红 */
  accent: {
    backgroundColor: backgroundColor.accent,
  },
  /** 绿 */
  secAccent: {
    backgroundColor: backgroundColor.secAccent,
  },
  lightGrey: {
    backgroundColor: backgroundColor.lightGrey,
  },
  palegrey: {
    backgroundColor: backgroundColor.palegrey,
  },
  grey: {
    backgroundColor: backgroundColor.grey,
  },
});

export const border = StyleSheet.create({
  primary: {
    borderWidth: 1,
    borderColor: basicColor.primary,
  },
  red: {
    borderWidth: 1,
    borderColor: basicColor.red,
  },
  dark: {
    borderWidth: 1,
    borderColor: basicColor.dark,
  },
  white: {
    borderWidth: 1,
    borderColor: basicColor.white,
  },
  main: {
    borderWidth: 1,
    borderColor: fontColor.main,
  },
  second: {
    borderWidth: 1,
    borderColor: fontColor.second,
  },
  accent: {
    borderWidth: 1,
    borderColor: fontColor.accent,
  },
  secAccent: {
    borderWidth: 1,
    borderColor: fontColor.secAccent,
  },
  greyAccent: {
    borderWidth: 1,
    borderColor: fontColor.grey,
  },
});

export const borderRadius = StyleSheet.create({
  /** 2 */
  xxs: {
    borderRadius: borderRadiusSize.xxs,
  },
  /** 4 */
  xs: {
    borderRadius: borderRadiusSize.xs,
  },
  /** 6 */
  s: {
    borderRadius: borderRadiusSize.s,
  },
  /** 8 */
  m: {
    borderRadius: borderRadiusSize.m,
  },
  /** 10 */
  l: {
    borderRadius: borderRadiusSize.l,
  },
  /** 12 */
  xl: {
    borderRadius: borderRadiusSize.xl,
  },
});

export const shadow = {
  defaultShadow: {
    startColor: 'rgba(204, 206, 228, 0.5)',
    distance: 8,
    style: {},
  },
};

if (globalStore.isWeb) {
  shadow.defaultShadow.distance = 0;
  shadow.defaultShadow.style = {
    boxShadow: '0 2px 8px 4px rgba(204, 206, 228, 0.5)',
  };
}

export const icon = StyleSheet.create({
  /** 12 */
  xxs: {
    width: iconSize.xxs,
    height: iconSize.xxs,
  },
  /** 12 */
  xs: {
    width: iconSize.xs,
    height: iconSize.xs,
  },
  /** 16 */
  s: {
    width: iconSize.s,
    height: iconSize.s,
  },
  /** 20 */
  m: {
    width: iconSize.m,
    height: iconSize.m,
  },
  /** 24 */
  l: {
    width: iconSize.l,
    height: iconSize.l,
  },
  /** 28 */
  xl: {
    width: iconSize.xl,
    height: iconSize.xl,
  },
  /** 32 */
  xxl: {
    width: iconSize.xxl,
    height: iconSize.xxl,
  },
});

export const image = StyleSheet.create({
  /** 36 */
  xs: {
    width: imageSize.xs,
    height: imageSize.xs,
  },
  /** 48 */
  s: {
    width: imageSize.s,
    height: imageSize.s,
  },
  /** 64 */
  m: {
    width: imageSize.m,
    height: imageSize.m,
  },
});
