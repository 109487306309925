import {Platform} from 'react-native';
import {BasicObject} from '../types';

// declare var fbq: any;

export const fbqTrack = (_key: string, _data?: BasicObject) => {
  if (Platform.OS !== 'web') {
    return;
  }
  // try {
  //   fbq?.('track', key, data);
  //   console.table([
  //     ['触发事件', key],
  //     ['事件参数', data ? JSON.stringify(data) : 'null'],
  //   ]);
  // } catch (error) {
  //   console.warn('fbq not loaded');
  // }
};
